import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { IoIosCheckmarkCircle } from "react-icons/io";

export const Success = () => {
  return (
    <Box bg={"white"} color={"black"} pb={"100px"}>
      <Flex justify={"center"} my={"40px"}>
        <IoIosCheckmarkCircle size={"8rem"} color={"green"} />
      </Flex>
      <Text mt={"50px"} textAlign={"center"} fontWeight={"semibold"}>
        Payment Successful
      </Text>
      <Flex justify={"center"} mb={"100px"} mt={"20px"}></Flex>
    </Box>
  );
};
